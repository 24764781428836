import React from 'react';

import SwitchTheme from '~/components/SwitchTheme';

import { SectionImage, Image } from './SectionImage.sx';
import Wrap from '~/components/Wrap';

export default ({ image, imageMobile, className, themeName, section }) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionImage
        className={className}
        section={{
          ...section,
          paddingBottom: section?.paddingBottom || 'none',
          paddingTop: section?.paddingTop || 'none',
        }}
      >
        <Wrap wrapSize={section?.wrapSize}>
          <Image image={image} imageMobile={imageMobile} />
        </Wrap>
      </SectionImage>
    </SwitchTheme>
  );
};
