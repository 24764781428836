import React from 'react';
import {
  getFluidGatsbyImage,
  // getFixedGatsbyImage
} from 'gatsby-source-sanity';
// import { buildImageObj, imageUrlFor } from '~/utils/sanity';
// import { breakpoints } from '~/config/variables';
import { Image, ImageBox, ImageMobile } from './Image.sx';

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
};

export default ({ className, image, imageMobile }) => {
  const asset = image?.asset;
  const assetMobile = imageMobile?.asset;
  if (!image || !asset) return null; // just in case there is no image.

  const fluidProps = getFluidGatsbyImage(
    asset?.id,
    { maxWidth: 1500 },
    sanityConfig,
  );

  const fluidPropsMobile = assetMobile
    ? getFluidGatsbyImage(assetMobile?.id, { maxWidth: 1500 }, sanityConfig)
    : null;

  if (asset.extension === 'svg') {
    delete fluidProps.srcWebp;
    delete fluidProps.srcSetWebp;
  }
  if (fluidPropsMobile && assetMobile?.extension === 'svg') {
    delete fluidPropsMobile.srcSetWebp;
    delete fluidPropsMobile.srcWebp;
  }

  return asset ? (
    <ImageBox className={className}>
      <Image fluid={fluidProps} hasMobile={fluidPropsMobile !== null} />
      {assetMobile && <ImageMobile fluid={fluidPropsMobile} />}
    </ImageBox>
  ) : null;
};
