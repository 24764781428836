import styled from '@emotion/styled';

import { media } from '~/utils/styles';

import ImageDecorationComponent from '~/components/ImageDecoration';
import Section from '~/components/Section';
import WrapComponent from '~/components/Wrap';
import Modules from '~/components/Modules';
import DecorationComponent from '~/components/Decoration';

export const SectionTextImage = styled(Section)(
  {
    display: 'flex',
  },
  props => ({
    background: props.theme.background,
  }),
);

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
});

export const Grid = styled(WrapComponent)(
  {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'fit-content() 1fr',
    gridTemplateAreas: `'content'
                        'decoration'`,
    [media('laptop')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      gridGap: 72,
      gridTemplateAreas: '"decoration content"',
    },
  },
  props => ({
    [media('laptop')]: {
      gridTemplateAreas: props.reverse
        ? '"decoration content"'
        : '"content decoration"',
    },
  }),
);

export const ImageDecoration = styled(ImageDecorationComponent)(
  {
    gridArea: 'decoration',
    alignSelf: 'center',
  },
  props => ({
    justifySelf: props.reverse ? 'flex-start' : 'flex-end',
  }),
);

export const Content = styled(Modules)({
  gridArea: 'content',
  alignSelf: 'center',
  h5: {
    marginBottom: 18,
  },
  h2: {
    marginBottom: 24,
  },
  h1: {
    marginBottom: 32,
  },
  p: {
    marginBottom: 18,
  },
  '.buttonset': {
    marginBottom: 18,
  },
});

export const Decoration = styled(DecorationComponent)({});
