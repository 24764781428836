import styled from '@emotion/styled';
import { Box as BoxComponent } from 'theme-ui';

import { media, hexToRGB } from '~/utils/styles';

import Section from '~/components/Section';
import WrapComponent from '~/components/Wrap';
import Modules from '~/components/Modules';
import Icon from '~/components/Icon';

export const SectionLocation = styled(Section)({
  display: 'flex',
  position: 'relative',
  minHeight: '400px',
  alignItems: 'center',
  // remove cursor from map
  // since the map cant make use of emotion nor the classes within
  // the map are defined right this hack here does its duty ~DH
  '.gm-style': {
    ' > div': {
      cursor: 'initial !important',
    },
  },
});

// since there is no className on GoogleMap component we have to use a wrapper component using
// important. A ticket is open
export const GoogleMapWrap = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 'auto !important',
  minHeight: '60% !important',
  overflow: 'hidden',
  [media('laptop')]: {
    minHeight: '100% !important',
  },
  '> div': {
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '50% !important',
    [media('laptop')]: {
      minHeight: 'inherit !important',
    },
  },
});

export const Circle = styled('div')(
  {
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
  },
  props => ({
    width: props.circleWidth,
    height: props.circleHeight,
    background: hexToRGB(props.theme.greenSecondary, 0.2),
    border: `3px solid ${props.theme.greenSecondary}`,
  }),
);

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
  position: 'relative',
});

export const Grid = styled('div')({
  display: 'grid',
  paddingTop: '50vh',
  alignItems: 'end',
  [media('laptop')]: {
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'end',
    gridTemplateColumns: '50%',
    gridTemplateRows: '1fr',
    gridTemplateAreas: 'content',
  },
});

export const Content = styled(Modules)({
  gridArea: 'content',
  'h5 + h1': {
    marginTop: 18,
  },
  h1: {
    marginBottom: 18,
  },
  h2: {
    marginBottom: 24,
  },
  h3: {
    marginBottom: 18,
  },
  'p + h5': {
    marginTop: 36,
  },
  h5: {
    marginBottom: 18,
  },
  p: {
    marginBottom: 12,
  },
  'p + h3': {
    marginTop: 36,
  },
  table: {
    marginTop: 24,
    marginBottom: 24,
  },
  '.buttonset': {
    marginTop: 36,
    marginBottom: 36,
  },
});

export const Box = styled(BoxComponent)(
  {
    background: 'white',
    paddingTop: 36,
    paddingLeft: 36,
    paddingRight: 36,
  },
  props => ({
    border: `1px solid ${props.theme.greyQuintary}`,
  }),
);

export const PinWrap = styled('div')({
  width: 36,
  height: 36,
  transform: 'translate(-50%, -50%)',
  color: '#ffffff',
});

export const Pin = styled(Icon)(
  {
    width: 36,
    height: 36,
  },
  props => ({ color: props.theme.greenSecondary }),
);
