import styled from '@emotion/styled';

import { grid } from '~/config/variables';
import { media, toPx } from '~/utils/styles';
import { Box } from 'theme-ui';

export const Wrap = styled(Box)(
  {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
  },
  props => ({
    padding:
      props.wrapSize === 'full'
        ? 0
        : props.wrapSize === 'small'
        ? `0 ${toPx(grid.horrizontalSpaceSmall)}`
        : `0 ${toPx(grid.horrizontalSpace)}`,

    [media(1900)]: {
      padding: props.wrapSize === 'small' ? '0px 170px' : '0px 100px',
      maxWidth: 1900,
    },
  }),
);
