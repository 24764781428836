export const colors = {
  black: '#000000',
  lightBlack: '#1C1D22',
  white: '#ffffff',

  orange: '#e96232',
  grey: '#A59E92',
  lightGrey: '#C7C7C7',
  darkGrey: '#6B6760',
  darkerGrey: '#393939',
};

export const sizes = {
  size0: 2,
  size1: 3,
  size2: 4,
  size3: 6,
  size4: 8, // sizes.spacing
  size5: 10, // base metric power of 10
  size6: 12,
  size7: 18,
  size8: 20,
  size9: 24, // base spacing number magic 24
  size10: 30,
  size11: 32,
  size12: 36,
  size13: 40,
  size14: 48,
  size15: 50, // sizes.radius
  size16: 56,
  size17: 60,
  size18: 64,
  size19: 70,
  size20: 72,
  size21: 80,
  size22: 90,
  size23: 96,
  size24: 100,
  size25: 120,
  size26: 144,
  size27: 168,
  size28: 192,
  size29: 240,
  size30: 360,
  size31: 480,
  size32: 720,
  size33: 960,
  size34: 1200,
  size35: 1440,
  size36: 2880,
};

export const spaces = Object.keys(sizes).map(key => sizes[key]);

export const fonts = {
  fontPrimary: 'Open Sans',
};

export const breakpoints = {
  zero: 0, // xs: 0,
  mobile: 481, // sm: 576,
  tablet: 737, // md: 950,
  laptop: 977, // lg: 1000,
  desktop: 1241, // xl: 1200,
};

// we change the breakpoint object to an array and remove the zero value
// here since rebass adds a zero by default. also we add a unit (px) ~DH
export const breakpointsRebass = Object.keys(breakpoints)
  .filter(key => key !== 'zero' && key)
  .map(key => `${breakpoints[key]}px`);

export const grid = {
  gutter: 24, // gutter: 16,
  horrizontalSpace: '8.5vw',
  horrizontalSpaceSmall: '15vw',
  width: {
    zero: '100%', // xs: '100%',
    mobile: '100%', // sm: 540,
    tablet: '100%', // md: 720,
    laptop: '100%', // lg: 964,
    desktop: 1500, // xl: 1200,
  },
};

export const indices = {
  indexZero: -1,
  indexPrimary: 1,
  indexSecondary: 10,
  indexTertiary: 50,
  indexQuaternary: 100,
  indexQuinary: 200,
  indexSenary: 300,
  indexSeptenary: 600,
  indexOctonary: 999,
  indexNonary: 10000,
  indexDenary: 50000,
};

export const radices = {
  radiusPrimary: sizes.size0,
  radiusSecondary: sizes.size2,
  radiusTertiary: sizes.size4,
  radiusQuaternary: sizes.size5,
};

// export const radii = [].push(...radices);

export const gradients = {
  whiteToWhite: [colors.white, colors.white],
  whiteTriple: [colors.white, colors.white, colors.white],
};

// The problem with numbers here is that 0 is handled as null which is
// problematic for gradient util function if someone knows better let me know ~DH
export const alphaMaps = {
  zeroToHundred: ['0', '1'],
  zeroHundredHundred: ['0', '1', '1'],
  hundredToZero: ['1', '0'],
  fiftyFifty: ['0.5', '0.5'],
};

export const positionMaps = {
  zeroEightyHundred: ['0%', '80%', '100%'],
};
