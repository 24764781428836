import { sx } from '@lucifer/sx';

import { media } from '~/utils/styles';

import { Box } from 'theme-ui';
import Section from '~/components/Section';
import WrapComponent from '~/components/Wrap';
import Modules from '~/components/Modules';
import Image from '~/components/Image';

export const SectionImageTiles = sx(Section)(
  {
    display: 'flex',
  },
  props => ({
    background: props.theme.background,
  }),
);

export const Wrap = sx(WrapComponent)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  // margin: '-30px',
  // width: 'calc(100% + 60px)',
});

export const Tile = sx(Box)(props => ({
  width: '50%',
  [media('tablet')]: {
    width: '33.33%',
  },
  [media('laptop')]: {
    width: `${(1 / props.columns) * 100}%`,
  },
  position: 'relative',
  paddingTop: props.spacing === 'noSpacing' ? 0 : 50,
}));

const getSize = size => {
  let calculatedSize = '50%';

  switch (size) {
    case 'huge':
      calculatedSize = '90%';
      break;
    case 'large':
      calculatedSize = '75%';
      break;
    case 'medium':
      calculatedSize = '50%';
      break;
    case 'small':
      calculatedSize = '25%';
      break;
    case 'mini':
      calculatedSize = '12.5%';
      break;
    default:
      calculatedSize = '50%';
      break;
  }

  return calculatedSize;
};

export const TileImage = sx(Image)(props => ({
  width: '100%',
  height: '100%',
  objectFit: props.imageSize || 'none',
  objectPosition: props.imagePosition || 'center bottom',
  position: 'absolute',
  top: 0,
  left: 0,

  img: {
    objectFit: `${props.imageSize || 'none'} !important`,
  },
}));

export const TileImageWrapper = sx(Box)(props => ({
  maxWidth: props.spacing === 'noSpacing' ? '100%' : getSize(props.size),
  position: 'relative',
  margin: '0 auto',
}));

export const TileImageSizer = sx(Box)(props => ({
  width: '100%',
  position: 'relative',
  paddingTop: '100%',
}));

export const Content = sx(Modules)({
  color: 'white',
  textAlign: 'center',
  padding: '30px 30px 30px',
  h1: {
    marginBottom: 10,
  },
  h2: {
    marginBottom: 10,
  },
  h3: {
    marginBottom: 10,
  },
  h4: {
    marginBottom: 10,
  },
  h5: {
    marginBottom: 10,
  },
  p: {
    marginBottom: 10,
    color: 'greySenary',

    '&+h2, &+h3, &+h1': {
      marginTop: 70,
    },
  },
  ul: {
    marginBottom: 10,
    color: 'greySenary',
    li: {
      '&:before': {
        color: 'inherit',
      },
    },
  },
  ol: {
    marginBottom: 10,
    color: 'greySenary',
    li: {
      '&:before': {
        color: 'inherit',
      },
    },
  },
  '.buttonset': {
    marginBottom: 18,
  },
});
