/**
 * Transforms hex to rgb(a)
 * NOTE: just works for full hex values not short syntax!
 *
 * Usage:
 * hexToRGB('#FF0000')
 * hexToRGB('#FF0000', 0.5);
 *
 * @param {string} hex
 * @param {string} alpha
 *
 * @return string
 */
export function hexToRGB(hex, alpha) {
  if (typeof hex !== 'string') return 'red';
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r},${g},${b},${alpha})` : `rgb(${r},${g},${b})`;
}
