import React from 'react';

import SwitchTheme from '~/components/SwitchTheme';

import {
  SectionImageTiles,
  Wrap,
  Tile,
  TileImage,
  TileImageWrapper,
  TileImageSizer,
  Content,
} from './SectionImageTiles.sx';

export default ({
  images,
  imageTileSettings,
  className,
  themeName,
  section,
}) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionImageTiles className={className} section={section}>
        <Wrap wrapSize={section?.wrapSize}>
          {images.map(image => {
            return (
              <Tile
                key={image._key}
                columns={imageTileSettings.columns}
                spacing={imageTileSettings?.spacing}
              >
                {image.image && (
                  <TileImageWrapper
                    size={imageTileSettings?.imageSettings?.boxSize}
                    spacing={imageTileSettings?.spacing}
                  >
                    <TileImageSizer>
                      <TileImage
                        image={image.image}
                        imageSize={imageTileSettings?.imageSettings?.imageSize}
                        imagePosition={
                          imageTileSettings?.imageSettings?.imagePosition
                        }
                      />
                    </TileImageSizer>
                  </TileImageWrapper>
                )}
                {image.text && <Content content={image.text} />}
              </Tile>
            );
          })}
        </Wrap>
      </SectionImageTiles>
    </SwitchTheme>
  );
};
