import {
  fonts,
  sizes,
  spaces,
  colors,
  indices,
  radices,
  breakpointsRebass,
} from '~/config/variables';

export const theme = {
  name: 'dark',
  type: 'dark',

  // font
  headlineFont: fonts.fontPrimary,

  // global
  background: colors.black,

  // component Logo
  logoTextColor: colors.white,

  // component Text
  textPColor: colors.white,

  // component List
  listUlColor: colors.white,
  listUlLiBulletColor: colors.white,
  listOlColor: colors.white,
  listDlColor: colors.white,

  // component Headline
  headlineH1Color: colors.white,
  headlineH2Color: colors.white,
  headlineH3Color: colors.white,
  headlineH4Color: colors.white,
  headlineH5Color: colors.white,
  headlineH6Color: colors.white,

  // component Subline
  sublineS1Color: colors.grey,

  // TODO: replace depricated stuff
  ...sizes,
  spaces,
  ...indices,
  ...radices,
  breakpoints: breakpointsRebass,
  spaceTop: 80,
  spaceTopLaptop: 120,
  spaceBottom: 80,
  spaceBottomLaptop: 120,
  buttonGhostBorderColor: 'white',
  buttonGhostColor: 'white',
  buttonGhostBackgroundColorHover: 'white',
  buttonGhostColorHover: 'black',
  buttonSolidColorHover: 'white',
  buttonSolidBackgroundHover: '#ff6600',
  buttonSolidBackground: 'white',
  buttonSolidColor: 'black',
};
