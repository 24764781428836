import React from 'react';

import SwitchTheme from '~/components/SwitchTheme';

import {
  ImageNone,
  ImageDecorationNone,
  ImageDecorationOne,
  ImageOne,
  DecorationOne,
  ImageDecorationTwo,
  ImageTwo,
  DecorationTwo,
  ImageDecorationThree,
  DecorationThreeIchi,
  DecorationThreeNi,
  DecorationThreeSan,
  DecorationThreeYon,
  ImageThree,
} from './ImageDecoration.styled';

export default ({ className, imageDecoration }) => {
  const figure = imageDecoration?.figure;
  const themeName = imageDecoration?.themeName;
  const type = imageDecoration?.type;

  switch (type) {
    case 'none':
    default:
      return (
        <ImageDecorationNone className={className}>
          <ImageNone image={figure} />
        </ImageDecorationNone>
      );
    case 'one':
      return (
        <SwitchTheme themeName={themeName}>
          <ImageDecorationOne className={className}>
            <DecorationOne />
            <ImageOne image={figure} />
          </ImageDecorationOne>
        </SwitchTheme>
      );

    case 'two':
      return (
        <SwitchTheme themeName={themeName}>
          <ImageDecorationTwo className={className}>
            <DecorationTwo />
            <ImageTwo image={figure} />
          </ImageDecorationTwo>
        </SwitchTheme>
      );

    case 'three':
      return (
        <SwitchTheme themeName={themeName}>
          <ImageDecorationThree className={className}>
            <DecorationThreeIchi />
            <DecorationThreeNi />
            <DecorationThreeSan />
            <DecorationThreeYon />
            <ImageThree image={figure} />
          </ImageDecorationThree>
        </SwitchTheme>
      );
  }
};
