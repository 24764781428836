import { sx } from '@lucifer/sx';

import { media } from '~/utils/styles';

import { Box } from 'theme-ui';
import Section from '~/components/Section';
import WrapComponent from '~/components/Wrap';
import Modules from '~/components/Modules';
import DecorationComponent from '~/components/Decoration';

export const SectionText = sx(Section)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

export const Wrap = sx(WrapComponent)({
  alignSelf: 'center',
});

export const Content = sx(Modules)({
  h2: {
    marginBottom: 24,
  },
  h1: {
    marginBottom: 32,
  },
  h5: {
    marginBottom: 18,
  },
  p: {
    marginBottom: 18,
    color: 'greySenary',

    '&+h2, &+h3, &+h1': {
      marginTop: 70
    }
  },
  ul: {
    marginBottom: 18,
    color: 'greySenary',
    li: {
      '&:before': {
        color: 'inherit',
      },
    },
  },
  ol: {
    marginBottom: 18,
    color: 'greySenary',
    li: {
      '&:before': {
        color: 'inherit',
      },
    },
  },
  '.buttonset': {
    marginBottom: 18,
  },
});

export const ContentWrap = sx(Box)(
  props => ({
    maxWidth: props.fullWidth ? 'none' : 710
  })
);

export const Decoration = sx(DecorationComponent)({
  display: 'none',
  [media('tablet')]: {
    display: 'block',
  },
});
