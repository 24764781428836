import styled from '@emotion/styled';

import { media } from '~/utils/styles';

import Section from '~/components/Section';
import WrapComponent from '~/components/Wrap';
import Modules from '~/components/Modules';
import DecorationComponent from '~/components/Decoration';

export const SectionColumn = styled(Section)(
  {
    display: 'flex',
  },
  props => ({
    background: props.theme.background,
  }),
);

export const Wrap = styled(WrapComponent)({
  alignSelf: 'center',
});

export const Content = styled(Modules)({
  textAlign: 'center',
  'h1 + p': {
    paddingTop: 18,
  },
  marginBottom: 72,
});

export const Decoration = styled(DecorationComponent)({
  display: 'none',
  [media('tablet')]: {
    display: 'block',
  },
});
